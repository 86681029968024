import { useEffect, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { useTexture, useAspect, softShadows } from "@react-three/drei";
import gsap from "gsap";
import HeartSingle from "./HeartSingle";

softShadows({
  frustum: 4.1,
  size: 0.05,
  near: 9.5,
  samples: 12,
  rings: 11,
});

export default function ExperienceSingleHeart({
  setIsInitScreen,
  setIsIntroVideoScreen,
  setIsMainScreen,
  setIsSingleModelPage,
  setIsInitialLoadDone,
  isModelLinkOn,
  setIsModelLinkOn,
  canvasEl,
}) {
  const { size } = useThree();
  const bgMesh = useRef();
  const bgImage = useTexture("/textures/main-bg.jpg");

  useEffect(() => {
    setIsInitScreen(false);
    setIsIntroVideoScreen(false);
    setIsMainScreen(false);
    setIsSingleModelPage(true);

    const canvasTopParent = canvasEl.current.parentNode.parentNode;
    gsap.from(canvasTopParent, {
      alpha: 0,
      duration: 2,
      ease: "power2.inOut",
    });
  }, []);

  const scale = useAspect(
    1920, // Pixel-width
    1080, // Pixel-height
    1 // Optional scaling factor
  );

  return (
    <>
      <directionalLight
        castShadow={size.width > 960 ? true : false}
        position={[0, 1, 8]}
        intensity={0.25}
        shadow-camera-far={12}
        shadow-camera-top={1.2}
        shadow-camera-right={5.5}
        shadow-camera-bottom={-1.8}
        shadow-camera-left={-5.5}
      />
      <ambientLight intensity={0.5} />
      <HeartSingle
        isInitialLoadDone={true}
        setIsInitialLoadDone={setIsInitialLoadDone}
        isModelLinkOn={isModelLinkOn}
        setIsModelLinkOn={setIsModelLinkOn}
      />

      {size.width > 960 && (
        <mesh ref={bgMesh} scale={scale} position={[0, 0, -2.5]} receiveShadow>
          <planeGeometry />
          <meshStandardMaterial map={bgImage} />
        </mesh>
      )}
    </>
  );
}

import { useState, useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { Float, useGLTF, Text } from "@react-three/drei";
import { ModelPresentationControls } from "./ModelPresentationControls";
import CompassLinks from "./CompassLinks";

export default function CompassSingle({ isModelLinkOn, setIsModelLinkOn }) {
  const { size } = useThree();
  const { nodes, materials } = useGLTF("/models/compass/compass.glb");
  const compass = useRef();
  const compassGroup = useRef();
  const compassText = useRef();

  useEffect(() => {
    setIsModelLinkOn(true);
  }, []);

  const [modelSize, setModelSize] = useState(0.0018);
  const [fontSize, setFontSize] = useState(0.25);

  const calculateSize = () => {
    if (size.width > 1365) {
      setModelSize(0.002);
      setFontSize(0.25);
    } else {
      setModelSize((size.width / 10000) * 0.0145);
      setFontSize(size.width / 10000 + 0.11);
    }
  };

  const [modelPosition, setModelPosition] = useState({
    x: 0,
    y: 0,
  });

  const [textPosition, setTextPosition] = useState({
    x: 0,
    y: -1.8,
  });

  const calculatePosition = () => {
    if (size.width > 1365) {
      setModelPosition({
        x: 0,
        y: 0.05,
      });
      setTextPosition({
        x: 0,
        y: -2.15,
      });
    } else if (size.width > 960 && size.width < 1365) {
      setModelPosition({
        x: 0,
        y: 0.15,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.72,
      });
    } else {
      setModelPosition({
        x: 0,
        y: 0.4,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.45,
      });
    }
  };

  useEffect(() => {
    calculateSize();
    calculatePosition();
  }, [size.width]);

  return (
    <group
      ref={compassGroup}
      position-y={modelPosition.y}
      position-x={modelPosition.x}
    >
      <Float speed={1.1} rotationIntensity={0.6}>
        <ModelPresentationControls
          polar={[-0.25, 0.2]}
          azimuth={[-0.2, 0.2]}
          // config={{ mass: 2, tension: 400 }}
          // snap={{ mass: 6, tension: 400 }}
          config={{ mass: 4, tension: 75 }}
          snap={{ mass: 10, tension: 400 }}
          speed={1}
          cursor={false}
        >
          <mesh
            geometry={nodes.Left_Top.geometry}
            material={materials.Right_Top}
            scale={modelSize > 0.001 ? modelSize : 0.001}
            rotation={[0, -Math.PI / 2, 0]}
            castShadow
            ref={compass}
          >
            <CompassLinks isModelLinkOn={isModelLinkOn} />
          </mesh>
        </ModelPresentationControls>
      </Float>
      <Text
        font="/fonts/work-sans-v18-latin-800.woff"
        color={"#000"}
        fontSize={fontSize > 0.19 ? fontSize : 0.19}
        fontWeight={800}
        textAlign={"center"}
        position={[0, textPosition.y < -1.15 ? textPosition.y : -1.152, 1]}
        rotation-y={0}
        ref={compassText}
      >
        INDUSTRY COMMUNITY
      </Text>
    </group>
  );
}

//useGLTF.preload("/models/compass/compass.glb");

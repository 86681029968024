import { useState, useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { Float, useGLTF, Text } from "@react-three/drei";
import { ModelPresentationControls } from "./ModelPresentationControls";
import gsap from "gsap";
import CompassLinks from "./CompassLinks";

export default function Compass({
  isPlaying,
  isStartExperience,
  isRecommendationsScreen,
  isInitialLoadDone,
  isModelLinkOn,
  isModelToggle,
}) {
  const { size } = useThree();
  const { nodes, materials } = useGLTF("/models/compass/compass.glb");
  const compass = useRef();
  const compassGroup = useRef();
  const compassText = useRef();

  // Create variable that stores GSAP matchMedia for detect resolutions
  let mm = gsap.matchMedia();

  useEffect(() => {
    if (!isPlaying && !isInitialLoadDone) {
      compassInitAnimation1();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isStartExperience && !isInitialLoadDone) {
      compassInitAnimation2();
    }
  }, [isStartExperience]);

  useEffect(() => {
    mm.add("(min-width: 961px)", () => {
      if (isInitialLoadDone) {
        isRecommendationsScreen ? compassOutAnimation() : compassInAnimation();
      }
    });
    mm.add("(max-width: 960px)", () => {
      if (isInitialLoadDone && isStartExperience) {
        isRecommendationsScreen && isModelToggle && compassOutAnimation();
      }
    });
  }, [isRecommendationsScreen, isStartExperience]);

  useEffect(() => {
    mm.add("(max-width: 960px)", () => {
      isModelToggle && isStartExperience && compassInAnimation();
      !isModelToggle && isStartExperience && compassOutAnimation();
    });
  }, [isModelToggle]);

  const compassInitAnimation1 = () => {
    const compassPosition = compass.current.position;
    const compassGroupPosition = compassGroup.current.position;
    const compassTextPosition = compassText.current.position;
    const compassScale = compass.current.scale;

    mm.add("(min-width: 961px)", () => {
      gsap.set(compassGroupPosition, {
        x: 0,
        y: -0.15,
        z: 3,
      });
      gsap.set(compassScale, {
        x: modelInitSize.x > 0.00009 ? modelInitSize.x : 0.00157,
        y: modelInitSize.y > 0.00009 ? modelInitSize.y : 0.00137,
        z: modelInitSize.z > 0.00009 ? modelInitSize.z : 0.00144,
      });
      gsap.set(compassTextPosition, {
        x: 12,
      });
    });

    mm.add("(max-width: 960px)", () => {
      gsap.set(compassPosition, {
        x: 0,
        y: 0.16,
        z: 3,
      });
      gsap.set(compassScale, {
        x: 0.00157 * 0.52,
        y: 0.00137 * 0.52,
        z: 0.00144 * 0.52,
      });
      gsap.set(compassTextPosition, {
        x: 12,
      });
    });
  };

  const compassInitAnimation2 = () => {
    const compassCurrent = compass.current;
    const compassRotation = compass.current.rotation;
    const compassPosition = compass.current.position;
    const compassGroupPosition = compassGroup.current.position;
    const compassTextPosition = compassText.current.position;
    const compassScale = compass.current.scale;

    mm.add("(min-width: 961px)", () => {
      gsap.to(compassGroupPosition, {
        x: modelPosition.x,
        y: modelPosition.y,
        z: 0,
        duration: 2.5,
        ease: "power2.inOut",
      });
      gsap.to(compassScale, {
        x: modelSize > 0.0009 ? modelSize : 0.001,
        y: modelSize > 0.0009 ? modelSize : 0.001,
        z: modelSize > 0.0009 ? modelSize : 0.001,
        duration: 1.2,
        delay: 0.1,
        ease: "power2.inOut",
      });
      gsap.to(compassRotation, {
        z: 0.4,
        x: 0.5,
        y: -1.2,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(compassRotation, {
        z: 0,
        x: 0,
        y: -Math.PI / 2,
        duration: 0.8,
        delay: 1.2,
        ease: "power2.inOut",
      });
      gsap.to(compassTextPosition, {
        x: textPosition.x,
        duration: 3,
        ease: "power2.inOut",
      });
    });

    mm.add("(max-width: 960px)", () => {
      gsap.to(compassPosition, {
        x: 6,
        y: 0,
        z: 0,
        duration: 2.5,
        ease: "power2.inOut",
      });
      gsap.to(compassScale, {
        x: modelSize > 0.0009 ? modelSize : 0.001,
        y: modelSize > 0.0009 ? modelSize : 0.001,
        z: modelSize > 0.0009 ? modelSize : 0.001,
        duration: 1.2,
        delay: 0.1,
        ease: "power2.inOut",
      });
      gsap.to(compassRotation, {
        z: 0.4,
        x: 0.5,
        y: -1.2,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(compassRotation, {
        z: 0,
        x: 0,
        y: -Math.PI / 2,
        duration: 0.8,
        delay: 1.2,
        ease: "power2.inOut",
      });
      gsap.set(compassCurrent, {
        delay: 2,
        visible: false,
      });
      gsap.to(compassTextPosition, {
        x: 6,
        duration: 3,
        ease: "power2.inOut",
      });
    });
  };

  const compassOutAnimation = () => {
    const compassCurrent = compass.current;
    const compassRotation = compass.current.rotation;
    const compassPosition = compass.current.position;
    const compassTextPosition = compassText.current.position;

    mm.add("(min-width: 961px)", () => {
      gsap.to(compassRotation, {
        z: 0.7,
        x: 1,
        y: -2.2,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(compassPosition, {
        x: 6,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(compassTextPosition, {
        x: 12,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });

    mm.add("(max-width: 960px)", () => {
      gsap.to(compassRotation, {
        z: 0.7,
        x: 1,
        y: -2.2,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(compassPosition, {
        x: 6,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.set(compassCurrent, {
        delay: 1.4,
        visible: false,
      });
      gsap.to(compassTextPosition, {
        x: 6,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });
  };

  const compassInAnimation = () => {
    const compassCurrent = compass.current;
    const compassRotation = compass.current.rotation;
    const compassPosition = compass.current.position;
    const compassTextPosition = compassText.current.position;

    mm.add("(min-width: 961px)", () => {
      gsap.to(compassRotation, {
        z: 0,
        x: 0,
        y: -Math.PI / 2,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(compassPosition, {
        x: 0,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(compassTextPosition, {
        x: 0,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });

    mm.add("(max-width: 960px)", () => {
      gsap.set(compassCurrent, {
        visible: true,
      });
      gsap.to(compassRotation, {
        z: 0,
        x: 0,
        y: -Math.PI / 2,
        duration: 0.8,
        ease: "power2.inOut",
      });
      gsap.to(compassPosition, {
        x: 0,
        duration: 0.8,
        delay: 0.3,
        ease: "power2.inOut",
      });
      gsap.to(compassTextPosition, {
        x: 0,
        duration: 1,
        delay: 0.35,
        ease: "power2.inOut",
      });
    });
  };

  const [modelSize, setModelSize] = useState(0.0018);
  const [modelInitSize, setModelInitSize] = useState({
    x: 0.00157,
    y: 0.00137,
    z: 0.00144,
  });
  const [fontSize, setFontSize] = useState(0.25);

  const calculateSize = () => {
    if (size.width > 1365) {
      setModelSize(0.0018);
      setModelInitSize({
        x: 0.00157,
        y: 0.00137,
        z: 0.00144,
      });
      setFontSize(0.25);
    } else {
      setModelSize((size.width / 10000) * 0.0145);
      setModelInitSize({
        x: (size.width / 1000) * 0.00157 - 0.0004,
        y: (size.width / 1000) * 0.00137 - 0.0004,
        z: (size.width / 1000) * 0.00144 - 0.0004,
      });
      setFontSize(size.width / 10000 + 0.11);
    }
  };

  const [modelPosition, setModelPosition] = useState({
    x: 2.8,
    y: 0.15,
  });

  const [textPosition, setTextPosition] = useState({
    x: 0,
    y: -1.95,
  });

  const calculatePosition = () => {
    if (size.width > 1365) {
      setModelPosition({
        x: 2.8,
        y: 0.15,
      });
      setTextPosition({
        x: 0,
        y: -1.95,
      });
    } else if (size.width > 960 && size.width < 1365) {
      setModelPosition({
        x: size.width / 1000 + 1,
        y: 0.15,
      });
      setTextPosition({
        x: 0,
        y: -size.width / 1000 - 0.54,
      });
    } else {
      setModelPosition({
        x: 0,
        y: 0.75,
      });
      setTextPosition({
        x: 0,
        y: -1.2,
      });
    }
  };

  useEffect(() => {
    calculateSize();
    calculatePosition();
  }, [size.width]);

  return (
    <group
      ref={compassGroup}
      position-y={modelPosition.y}
      position-x={modelPosition.x}
    >
      <Float speed={1.1} rotationIntensity={0.6}>
        <ModelPresentationControls
          polar={[-0.25, 0.2]}
          azimuth={[-0.2, 0.2]}
          // config={{ mass: 2, tension: 400 }}
          // snap={{ mass: 6, tension: 400 }}
          config={{ mass: 4, tension: 75 }}
          snap={{ mass: 10, tension: 400 }}
          speed={1}
          cursor={false}
        >
          <mesh
            geometry={nodes.Left_Top.geometry}
            material={materials.Right_Top}
            scale={modelSize > 0.0009 ? modelSize : 0.001}
            rotation={[0, -Math.PI / 2, 0]}
            castShadow
            ref={compass}
          >
            <CompassLinks isModelLinkOn={isModelLinkOn} />
          </mesh>
        </ModelPresentationControls>
      </Float>
      <Text
        font="/fonts/work-sans-v18-latin-800.woff"
        color={"#000"}
        fontSize={fontSize > 0.19 ? fontSize : 0.19}
        fontWeight={800}
        textAlign={"center"}
        position={[0, textPosition.y < -1.2 ? textPosition.y : -1.15, 1]}
        rotation-y={0}
        ref={compassText}
      >
        INDUSTRY COMMUNITY
      </Text>
    </group>
  );
}

useGLTF.preload("/models/compass/compass.glb");
